import React from 'react';
import hero from '../images/banner-purple.jpg'

export default function Index() {
  return (
    <section className="text-gray-700 body-font">
      <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
        <div className="text-center bg:cover bg:center w-full" >
          <img src={hero} alt="purple from made of many" />
          <h1 className="centered-image title-font sm:text-4xl text-3xl font-medium text-gray-900">Camille Hamilton Oliver</h1>
          <h2 className="text-xl p-8">Say Hello</h2>
          <p>camille.glass@gmail.com</p>
        </div>
      </div>
    </section>
  )
};
